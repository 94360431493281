import Request from '../services/Request'

export default class UserRepository {
    /**
     * @returns {Promise<any>}
     */
    static user() {
        return new Promise((resolve, reject) => {
            Request
                .make('GET', '/users/me', {params: {include: 'unitGroups.units'}})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     * @param email
     * @param password
     * @returns {Promise}
     */
    static login({email, password}) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/login', {email, password})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     * @param email
     * @param password
     * @returns {Promise}
     */
    static getOauthTokensFromSsoWebLogin({key}) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/login/sso/tokens', {key})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     * @param email
     * @returns {Promise}
     */
    static resetPassword({email}) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/password/reset', {email})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     *
     * @param token
     * @returns {Promise<unknown>}
     */
    static passwordFindToken(token) {
        return new Promise((resolve, reject) => {
            Request
                .make('GET', '/password/find/'+token)
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     *
     * @param email
     * @param password
     * @param token
     * @returns {Promise<unknown>}
     */
    static passwordUpdate({email, password, token}) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/password/update', {email, password, token})
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    // /**
    //  *
    //  * @param body
    //  * @returns {Promise<any>}
    //  */
    // static register(body) {
    //     return new Promise((resolve, reject) => {
    //         Request
    //             .make('POST', '/register', body)
    //             .then(() => resolve())
    //             .catch((error) => reject(error))
    //     });
    // }

    /**
     * @returns {Promise<any>}
     */
    static logout() {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/logout')
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     * @returns {Promise}
     */
    static refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', '/login/refresh', {refresh_token: refreshToken})
                .then(({data}) => resolve(data))
                .catch((error) => reject(error))
        });
    }
}

