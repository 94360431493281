<template>
    <div id="locale-changer">
        <template v-if="languages.length === 2">
            <img :src="require('../../assets/images/flags/'+$i18n.locale+'.svg')" alt="flag" class="flag-icon-xs" style="width: 24px"
                 v-on:click="changeLanguage($i18n.locale === 'fr' ? 'en' : 'fr')">
        </template>
        <template v-else>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <img :src="require('../../assets/images/flags/'+$i18n.locale+'.svg')" alt="flag"
                         class="flag-icon-xs" v-on="on">
                </template>
                <v-list>
                    <v-list-item v-for="(lang, index) in availableLanguages" :key="index"
                                 @click="changeLanguage(lang)">
                        <v-list-item-icon class="mr-4">
                            <img :src="require('../../assets/images/flags/'+lang+'.svg')" alt="flag"
                                 class="flag-icon-xs">
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('languages.' + lang) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </div>
</template>

<script>
import Storage from "../../services/Storage";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {EventBus} from '../../services/Request'
import moment from "moment";

export default {
    name: 'locale-changer',

    mounted() {
        this.languages = process.env.VUE_APP_I18N_AVAILABLE_LOCALE.split(",");
        this.availableLanguages = this.languages.filter(l => l !== this.$i18n.locale);
    },

    props: {
        reload: {
            type: [Boolean],
            default: true
        },
    },

    data() {
        return {
            languages: [],
            availableLanguages: []
        }
    },

    methods: {
        changeLanguage(lang) {
            this.$i18n.locale = lang;
            moment.locale(lang)
            Storage.set('language', lang);
            this.availableLanguages = _.filter(this.languages, function (l) {
                return l !== lang
            });
            if(this.reload){
                window.location.reload()
            }
        },
    }
}
</script>