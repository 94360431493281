import AuthLogic from "./AuthLogic";

export default class RouterLogic {
    static redirect(to, from, next) {
        if (AuthLogic.hasAccessToken() && AuthLogic.hasRole('member') || AuthLogic.hasRole('quality') || AuthLogic.hasRole('moderator') || AuthLogic.hasRole('admin') || AuthLogic.hasRole('reader')) {
            if (to.name === 'home') {
                next({name: 'dashboard'})
            } else {
                next()
            }

        } else {
            console.warn('Delog redirect to login')
            AuthLogic
                .logout()
                .then(() => next({
                    name: 'home',
                    query: {redirect: to.fullPath}
                }))
        }
    }
}

