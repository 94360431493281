<template>
  <ValidationObserver v-slot="{ invalid, validated, passes, validate, handleSubmit }" ref="observer">

    <v-dialog v-model="dialog" max-width="250px" :fullscreen="$vuetify.breakpoint.mdAndDown || dialogFullScreen"
              :hide-overlay="$vuetify.breakpoint.mdAndDown" scrollable
              no-click-animation>
      <v-card>
<!--        <v-toolbar dark color="primary" :max-height="$vuetify.breakpoint.mdAndUp ? 64 : 56">-->
<!--          &lt;!&ndash;                    <v-btn icon dark @click="closeDialog">&ndash;&gt;-->
<!--          &lt;!&ndash;                        <v-icon>mdi-close</v-icon>&ndash;&gt;-->
<!--          &lt;!&ndash;                    </v-btn>&ndash;&gt;-->
<!--          <v-toolbar-title></v-toolbar-title>-->
<!--          Couleur du thème</v-toolbar-title>-->
<!--          <v-progress-linear-->
<!--              :active="saveLoading"-->
<!--              :indeterminate="saveLoading"-->
<!--              absolute-->
<!--              bottom-->
<!--              color="lime"-->
<!--          ></v-progress-linear>-->
<!--          <div class="flex-grow-1"></div>-->
<!--          <v-toolbar-items>-->
<!--            <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon @click="dialogFullScreen = !dialogFullScreen">-->
<!--              <v-icon v-if="dialogFullScreen === true">mdi-fullscreen-exit</v-icon>-->
<!--              <v-icon v-if="dialogFullScreen === false">mdi-fullscreen</v-icon>-->
<!--            </v-btn>-->
<!--            <v-btn dark text @click="passes(submit)">-->
<!--              <v-icon>mdi-content-save-outline</v-icon>-->
<!--            </v-btn>-->
<!--          </v-toolbar-items>-->
<!--        </v-toolbar>-->

        <v-card-text class="pb-0">
          <v-radio-group v-model="radioGroup">
            <v-radio
                :label="$t('views.layout.light_mode')"
                :value="0"
                @click="$vuetify.theme.dark = false; passes(submit)"
            ></v-radio>
            <v-radio
                :label="$t('views.layout.night_mode')"
                :value="1"
                @click="$vuetify.theme.dark = true; passes(submit)"
            ></v-radio>
            <v-radio
                :label="$t('views.layout.system_preferences')"
                :value="2"
                @click="updateDarkModeFromSystemParams; passes(submit)"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </ValidationObserver>

</template>

<script>
import {ValidationObserver} from 'vee-validate';
import AuthLogic from "@/logics/AuthLogic";
import ModelLogic from "@/logics/ModelLogic";

export default {
  name: 'dark-mode-dialog',

  props: {},

  components: {
    ValidationObserver,
  },

  data: () => ({
    dialog: false,
    dialogFullScreen: false,
    saveLoading: false,
    radioGroup: 0,
  }),

  created() {
  },

  mounted() {
    this.radioGroup = AuthLogic.user().has_dark_mode
  },

  computed: {},

  watch: {},

  methods: {
    openDialog() {
      return new Promise(resolve => {
        // this.loading = true;

        this.dialog = true;
        resolve()
      })
    },

    closeDialog() {
      return new Promise(resolve => {
        this.dialog = false;
        resolve()
      })
    },

    submit() {
      this.saveLoading = true;

      if (this.radioGroup !== 2) {
        this.$vuetify.theme.dark = this.radioGroup
      } else {
        const mq = window.matchMedia('(prefers-color-scheme: dark)')
        this.$vuetify.theme.dark = mq.matches
      }

      ModelLogic
          .updateOne('users', AuthLogic.user().id + '/darkmode', {'has_dark_mode': this.radioGroup})
          .then(() => {
            AuthLogic.loadUser()
            this.saveLoading = false;
            this.closeDialog()
          })
          .catch((error) => {
            this.saveLoading = false;
            console.error(error)
          })
    },

    updateDarkModeFromSystemParams() {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      this.$vuetify.theme.dark = mq.matches
    }
  },
};
</script>
