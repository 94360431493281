import Vue from 'vue'
import Router from 'vue-router'
import PublicRoutes from './public'
import GuardedAdminRoutes from './guardedAdmin'
import GuardedMemberRoutes from './guardedMember'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...PublicRoutes,
    ...GuardedAdminRoutes,
    ...GuardedMemberRoutes,
    // {
    //   path: '/',
    //   name: 'layout',
    //   component: Layout,
    //   children: [{
    //     path: '/',
    //     name: 'home',
    //     component: Home,
    //   }]
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
    // }
  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})