// Inspire by : https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f

import AuthLogic from "../logics/AuthLogic";
import {EventBus} from "./Request";
import UserStore from "../stores/UserStore";

const shouldIntercept = (error) => {
    try {
        return error.response.status === 401
            && !(error.response.data.code && error.response.data.code === "invalid_credentials")
    } catch (e) {
        return false;
    }
};

export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        shouldIntercept,
        ...customOptions,
    };

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const interceptor = (error) => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(() => {
                originalRequest._queued = true;
                return axiosClient.request(originalRequest);
            }).catch(() => {
                return Promise.reject(error);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            const token = AuthLogic.getTokens();

            AuthLogic
                .refreshToken(token.refresh_token)
                .then((tokenData) => {
                    processQueue(null, tokenData);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {
                    AuthLogic.removeUser()
                    AuthLogic.removeTokens()
                    UserStore.isLoggedIn = false;
                    EventBus.$emit('401-unauthorized');
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                })
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};
