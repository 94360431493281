<template>
	<router-view/>
</template>

<script>
import {EventBus} from "./services/Request";

export default {
	name: 'App',
	data: () => ({
		env: process.env.VUE_APP_ENV
	}),
	created() {
		EventBus.$on('401-unauthorized', () => {
			this.redirectToLogin()
		});
	},

	mounted() {
		if (this.env === 'preproduction') {
			let vwoScript = document.createElement('script')
			vwoScript.setAttribute('src', 'https://dev.visualwebsiteoptimizer.com/lib/544953.js')
			document.head.appendChild(vwoScript)
		}

		this.itemsPerPageOptions = process.env.VUE_APP_VUETIFY_TABLE_ITEMS_PER_PAGE_OPTIONS.split(",").map(Number);

		window.STONLY_WID = "c22aeebf-c1fc-11eb-8dbf-062882f67cfe";

		!function (s, t, o, n, l, y, w, g) {
			s.StonlyWidget || ((w = s.StonlyWidget = function () {
				w._api ? w._api.apply(w, arguments) : w.queue.push(arguments)
			}).scriptPath = n, w.queue = [], (y = t.createElement(o)).async = !0,
				(g = new XMLHttpRequest).open("GET", n + "version?v=" + Date.now(), !0), g.onreadystatechange = function () {
				4 === g.readyState && (y.src = n + "stonly-widget.js?v=" + (200 === g.status ? g.responseText : Date.now()),
					(l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l))
			}, g.send())
		}(window, document, "script", "https://stonly.com/js/widget/v2/");
	},

	methods: {
		redirectToLogin: _.debounce(function () {
			if (this.$route.name !== 'dashboard') {
				this.$router.push({name: 'dashboard', query: {redirect: this.$route.fullPath}})
			}
		}, 300),
	}
};
</script>

<style scoped lang="scss">

</style>