import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import Storage from "./services/Storage";
import i18n from './config/i18n';
import './plugins/vee-validate';
import './assets/styles/main.scss';
import axios from 'axios';
import moment from "moment";
import Helpers from "./services/Helpers";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueClipboard from 'vue-clipboard2'
import _ from "lodash";
import GlobalFiltersStore from "@/stores/GlobalFiltersStore";

Vue.config.productionTip = false

Vue.use(VueClipboard)

//Set default language
const actualLanguage = Storage.get('language')

if(actualLanguage){
  i18n.locale = actualLanguage
  Storage.set('language', actualLanguage)
} else {
  i18n.locale = process.env.VUE_APP_I18N_LOCALE
  Storage.set('language', process.env.VUE_APP_I18N_LOCALE)
}

//Lazy loading Vuei18n translations
const loadedLanguages = i18n.locale// our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  moment.locale(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}.js`).then(
      messages => {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
  )
}
//End lazy loading translations

router.beforeEach((to, from, next) => {
  const lang = i18n.locale
  loadLanguageAsync(lang).then(() => next())

  GlobalFiltersStore.comeFromPageWithoutGlobalFilters = _.includes(['dashboards', 'ratings', 'dashboard-unit-group', 'dashboard'], to.name) && !_.includes(['dashboards', 'ratings', 'dashboard-unit-group', 'dashboard'], from.name);

  GlobalFiltersStore.comeFromRatingPage = (to.name !== 'ratings' && from.name === 'ratings') || (to.name === 'ratings' && from.name === null) || (to.name === 'ratings' && from.name === 'ratings');
});


//Prototypes can be used as $helpers in template and this.$helpers in JS
Vue.prototype.$moment = moment;
Vue.prototype.$helpers = Helpers;
// Vue.prototype.$authLogic = AuthLogic;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDKCucqIqk4dlQhgAnrX1p95vneEjwhAPw',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')