import GlobalRepo from "../repositories/GlobalRepository";
import Helpers from "../services/Helpers";
import Request from "@/services/Request";
import _ from "lodash";

export default class ModelLogic {

    /**
     *
     * @param model
     * @param options
     * @param filters
     * @param method
     * @param cancelToken
     * @returns {Promise<unknown>}
     */
    static getAll(model, options, filters = {}, method = 'GET', cancelToken = () => {}) {
        let params = Object.assign(
            Helpers.parsePagination(options),
            Helpers.parseFilters(filters)
        );

        console.warn('getAll ' + model + ' (' + _.camelCase(model) + ')');

        return new Promise((resolve, reject) => {
            GlobalRepo
                .getAll(model, params, method, cancelToken)
                .then((result) => {
                    let paginated = false;
                    if (result.data.meta && result.data.meta.pagination) {
                        paginated = true;
                        Helpers.syncPagination(options, result.data.meta.pagination)
                    }

                    resolve({
                        [_.camelCase(model)]: result.data.data,
                        totalItems: paginated ? result.data.meta.pagination.total : result.data.data.length
                    })
                })
                .catch((error) => reject(error))
        });
    }

    /* By UnitGroup */
    /**
     *
     * @param parentModel
     * @param model
     * @param parentUuid
     * @param options
     * @param filters
     * @returns {Promise<any>}
     */
    static getAllByParent(parentModel, model, parentUuid, options, filters = {}, cancelToken = () => {}) {
        let params = Object.assign(
            Helpers.parsePagination(options),
            Helpers.parseFilters(filters)
        );

        console.warn('getAll ' + model + ' byParent ' + parentModel + ' (' + _.camelCase(model) + ')');

        return new Promise((resolve, reject) => {
            GlobalRepo
                .getAllByParent(parentModel, model, parentUuid, params, cancelToken)
                .then((result) => {
                    let paginated = false;

                    if (result.data.meta && result.data.meta.pagination) {
                        paginated = true;
                        Helpers.syncPagination(options, result.data.meta.pagination)
                    }

                    resolve({
                        [_.camelCase(model)]: result.data.data,
                        totalItems: paginated ? result.data.meta.pagination.total : result.data.data.length
                    })
                })
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param params
     * @returns {Promise<any>}
     */
    static getOne(model, uuid, params = {}) {
        console.warn('getOne ' + model);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .getOne(model, uuid, params)
                .then((result) => resolve(result.data.data))
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param body
     * @returns {Promise<any>}
     */
    static store(model, body) {
        console.warn('store ' + model);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .store(model, body)
                .then((result) => {
                    if(!_.isEmpty(result.data.data)){
                        resolve(result.data.data)
                    } else {
                        resolve(result)
                    }
                })
                .catch((error) => reject(error));
        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param body
     * @returns {Promise<any>}
     */
    static updateOne(model, uuid, body = {}) {
        console.warn('updateOne ' + model);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .updateOne(model, uuid, body)
                .then((result) => {
                    resolve(result.data.data)
                })
                .catch((error) => reject(error))
        });
    }


    static update(model, body = {}) {
        console.warn('update ' + model);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .update(model, body)
                .then((result) => {
                    resolve(result.data.data)
                })
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model - (attach to model)
     * @param uuid - (attach to model uuid)
     * @param attachedModel - (attached model)
     * @param body - (array of attached model uuid to detach from "attach to" model)
     * @returns {Promise<any>}
     */
    static attach(model, uuid, attachedModel, body = {}) {
        console.warn('attach ' + model + ' to ' + attachedModel);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .attach(model, uuid, attachedModel, body)
                .then((result) => {
                    resolve(result.data.data)
                })
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model - (detach from model)
     * @param uuid - (detach from model uuid)
     * @param detachedModel - (detached model)
     * @param body - (array of detached model uuid to detach from "detach from" model)
     * @returns {Promise<any>}
     */
    static detach(model, uuid, detachedModel, body = {}) {
        console.warn('detach ' + model + ' to ' + detachedModel);

        return new Promise((resolve, reject) => {
            GlobalRepo
                .detach(model, uuid, detachedModel, body)
                .then(() => {
                    resolve()
                })
                .catch((error) => reject(error))
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static delete(object, uuid) {
        return new Promise((resolve, reject) => {
            Request
                .make('DELETE', '/'+object+'/'+uuid+'')
                .then(({data}) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }
}

