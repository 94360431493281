<template>
    <v-app class="purple">

        <!--Content-->
        <v-main>
            <router-view/>
        </v-main>

        <!--Footer-->
<!--        <v-footer padless app>-->
<!--            <v-row justify="center">-->
<!--                <span>&copy; 2010 - {{ this.$moment().year() }}</span>-->
<!--            </v-row>-->
<!--        </v-footer>-->
    </v-app>
</template>

<script>
    import LocaleChanger from "../../components/communs/LocaleChanger";

    export default {
        name: 'PublicLayout',

        mounted(){
        },

        components: {
            LocaleChanger
        },

        data: () => ({
        }),

        methods: {

        }
    };
</script>