<template>
	<v-dialog v-model="dialog" max-width="700px" :fullscreen="$vuetify.breakpoint.mdAndDown || dialogFullScreen"
	          :hide-overlay="$vuetify.breakpoint.mdAndDown" scrollable content-class="top-dialog"
	          no-click-animation :persistent="true" @click:outside="emitUpdateGlobalFiltersEvent">

		<template v-slot:activator="{ on, attrs }" v-if="!noDisplay">
			<div
				v-bind="attrs"
				v-on="on"
				class="mx-1"
			>
        <span class="py-1 px-3 d-inline-block primary rounded" style="color:white">
          <span class="caption">{{ selectedUnitGroupsText }}</span>
          <template v-if="selectedUnitGroupsText.length && $vuetify.breakpoint.mdAndUp"> - </template>
          <br v-if="$vuetify.breakpoint.smAndDown && selectedUnitGroupsText.length">
          <span class="caption">{{ selectedUnitsText }}</span>
          <template v-if="selectedUnitsText.length && $vuetify.breakpoint.mdAndUp"> - </template>
          <br v-if="$vuetify.breakpoint.smAndDown">
          <span
	          class="caption">{{ computedStartDateFormattedWithFullMonth }} {{
		          $t('views.global.filters.to')
	          }} {{ computedEndDateFormattedWithFullMonth }}</span>
          <v-icon class="ml-2 d-inline-block" style="color:white" size="15" v-if="$vuetify.breakpoint.mdAndUp">mdi-filter-menu</v-icon>
        </span>
			</div>
		</template>

		<v-card id="GF">
			<v-toolbar dark color="primary" :max-height="$vuetify.breakpoint.mdAndUp ? 64 : 56">
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-card-actions>
						<v-btn
							outlined
							class="mr-3"
							@click="emitUpdateGlobalFiltersEvent(); dialog = false; RatingStore.ratingsToShare= [];"
							:disabled="hasFilters">
							{{ $t('views.global.filters.apply_filters') }}
						</v-btn>
					</v-card-actions>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-container fluid class="pt-5 pb-0">
					<v-row class="py-3">
						<!--Unit groups-->
						<v-col class="col-12 col-md-6 pr-md-5">
							<v-autocomplete
								v-model="GlobalFiltersStore.selectedUnitGroups"
								:items="unitGroups"
								:search-input.sync="unitGroupsSearchInput"
								multiple
								dense
								hide-details
								:prefix="selectedUnitGroupsModalText"
								:filter="searchFilters"
								item-text="name"
								@change="getSelectedUnitGroupsWithUnits(false); withRouteParams = false; getSelectedUnitGroupsText()"
								return-object
								outlined
								clearable
								ref="unitGroupAutoComplete"
							>
								<template slot="label">
									<v-icon small left class="mr-0 mb-1">mdi-map-marker-multiple</v-icon>
									{{ $t('views.unit_group.title') }}
								</template>
								<template v-slot:prepend-item>
									<v-list-item @click="toggleUnitGroups">
										<v-list-item-action>
											<v-icon
												:color="GlobalFiltersStore.selectedUnitGroups.length > 0 ? 'primary' : ''">
												{{ selectAllUnitGroupsIcon }}
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>
												<template v-if="selectAllUnitGroups">
													{{ $t('views.global.deselect_all') }}
												</template>
												<template v-else>
													{{ $t('views.global.select_all') }}
												</template>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2"></v-divider>
								</template>

								<template v-slot:selection="{ item, index }">

								</template>
							</v-autocomplete>
						</v-col>

						<!--Units-->
						<v-col class="col-12 col-md-6 pl-md-5">
							<v-autocomplete
								v-model="GlobalFiltersStore.selectedUnits"
								:items="units"
								:disabled="unitsSelectDisabled"
								:loading="unitsSelectLoading"
								:search-input.sync="unitSearchInput"
								multiple
								dense
								outlined
								hide-details
								:prefix="selectedUnitsModalText"
								@click="togglePlaceholder = !togglePlaceholder"
								:filter="searchFilters"
								@change="withRouteParams = false; unitSearchInput = ''; getSelectedUnitsText(); getChannels()"
								item-text="name"
								item-value="units_unit_groups_id"
								return-object
								clearable
								ref="unitAutoComplete"
							>
								<template slot="label">
									<v-icon small left class="mr-0 mb-1">mdi-map-marker</v-icon>
									{{ $t('views.unit.title') }}
								</template>

								<template v-slot:prepend-item>
									<v-list-item @click="toggleUnits">
										<v-list-item-action>
											<v-icon
												:color="GlobalFiltersStore.selectedUnits.length > 0 ? 'primary' : ''">
												{{ selectAllUnitsIcon }}
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>
												<template v-if="selectAllUnits">
													{{ $t('views.global.deselect_all') }}
												</template>
												<template v-else>
													{{ $t('views.global.select_all') }}
												</template>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2"></v-divider>
								</template>

								<template v-slot:selection="{ item, index }">

								</template>

								<!--								<template v-slot:item="{ item, index }">-->
								<!--									{{ item.name }} éé-->
								<!--								</template>-->
							</v-autocomplete>

						</v-col>

						<!--Dates-->
						<v-col class="col-12 col-md-6 pr-md-5">
							<v-menu
								v-model="startDatemenu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										:value="computedStartDateFormattedWithFullMonth"
										readonly
										outlined
										dense
										hide-details
										v-on="on"
									>
										<template slot="label">
											<v-icon small left class="mr-0 mb-1">mdi-calendar</v-icon>
											{{ $t('views.global.filters.date_picker_start') }}
										</template>
									</v-text-field>

								</template>
								<v-date-picker
									v-model="startDate"
									@input="startDatemenu = false"
									:min="minStartDate"
									:max="maxStartDate"
									first-day-of-week="1"
									:locale="$i18n.locale"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col class="col-12 col-md-6 pl-md-5">
							<v-menu
								v-model="endDatemenu"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										:value="computedEndDateFormattedWithFullMonth"
										readonly
										outlined
										dense
										hide-details
										v-on="on"
									>
										<template slot="label">
											<v-icon small left class="mr-0 mb-1">mdi-calendar</v-icon>
											{{ $t('views.global.filters.date_picker_end') }}
										</template>
									</v-text-field>
								</template>
								<v-date-picker
									v-model="endDate"
									@input="endDatemenu = false"
									:min="minEndDate"
									:max="maxEndDate"
									first-day-of-week="1"
									:locale="$i18n.locale">
								</v-date-picker>
							</v-menu>
						</v-col>

						<!--SOURCE-->
						<v-col class="col-12">
							<v-select
								v-model="GlobalFiltersStore.sources"
								:items="sourceFilters"
								item-value="value"
								item-text="name"
								outlined
								multiple
								chips
								deletable-chips
								small-chips
								:loading="loadingSourcesFilter"
								class="caption"
								hide-details
								dense
							>
								<template slot="label">
									<v-icon small left class="mr-0 mb-1">mdi-format-list-bulleted-type</v-icon>
									{{ $t('views.rating.filters.source.title') }}
								</template>
								<template #selection="{ item, index }">
									<v-chip color="primary" small class="mt-1" close close-icon="mdi-close-circle"
									        @click:close="GlobalFiltersStore.sources.splice(index, 1)">{{ item.name }}
									</v-chip>
								</template>
							</v-select>
						</v-col>

						<!--CHANNELS-->
						<v-col class="col-12" v-if="channels.length">
							<v-autocomplete
								v-model="GlobalFiltersStore.selectedChannels"
								:items="channels"
								:disabled="channelsSelectDisabled"
								:loading="channelsSelectLoading"
								:search-input.sync="channelsSearchInput"
								multiple
								dense
								outlined
								hide-details
								chips
								deletable-chips
								small-chips
								:filter="searchFilters"
								@change="channelsSearchInput = '';"
								item-text="name"
								item-value="uuid"
								clearable
								ref="channelsAutoComplete"
							>
								<template slot="label">
									<v-icon small left class="mr-0 mb-1">mdi-filter-variant</v-icon>
									{{ $t('views.global.filters.channel') }}
								</template>

								<template v-slot:prepend-item>
									<v-list-item @click="toggleChannels">
										<v-list-item-action>
											<v-icon
												:color="GlobalFiltersStore.selectedChannels.length > 0 ? 'primary' : ''">
												{{ selectAllChannelsIcon }}
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title>
												<template v-if="selectAllChannels">
													{{ $t('views.global.deselect_all') }}
												</template>
												<template v-else>
													{{ $t('views.global.select_all') }}
												</template>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-2"></v-divider>
								</template>
								<template #selection="{ item, index }">
									<v-chip color="primary" small class="mt-1" close close-icon="mdi-close-circle"
									        @click:close="GlobalFiltersStore.selectedChannels.splice(index, 1)">{{ item.name }}
									</v-chip>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import AuthLogic from "../../logics/AuthLogic";
import moment from "moment";
import ModelLogic from "../../logics/ModelLogic";
import _ from "lodash";
import Helpers from "../../services/Helpers";
import {EventBus} from '@/services/Request';
import GlobalFiltersStore from "@/stores/GlobalFiltersStore";
import RatingStore from '@/stores/RatingStore';

export default {
	name: 'global-filters',

	props: {
		noDisplay: {
			type: [Boolean],
			default: false
		},
	},

	data: () => ({
		Helpers,
		GlobalFiltersStore,
		RatingStore,
		firstLaunch: true,

		/*Unit groups*/
		unitGroups: [],
		selectedUnitGroupsText: '',
		selectedUnitGroupsModalText: '',
		unitGroupsSearchInput: '',

		/*Units*/
		units: [],
		unitsSelectLoading: false,
		unitsSelectDisabled: true,
		unitSearchInput: '',
		selectedUnitsText: '',
		selectedUnitsModalText: '',

		/*Channels*/
		channels: [],
		channelsSelectLoading: false,
		channelsSelectDisabled: true,
		channelsSearchInput: '',

		/*Dates*/
		startDatemenu: false,
		endDatemenu: false,
		minStartDate: moment('2005-01-01').format('YYYY-MM-DD'),
		maxStartDate: moment().format('YYYY-MM-DD'),
		minEndDate: moment('2005-01-01').format('YYYY-MM-DD'),
		maxEndDate: moment().format('YYYY-MM-DD'),

		/*Dialog*/
		dialog: false,
		dialogFullScreen: false,

		env: process.env.VUE_APP_ENV,

		/*Sources*/
		sourceFilters: [],
		routeParams: {},
		loadingSourcesFilter: false,

		togglePlaceholder: false,
		withRouteParams: true,
	}),

	created() {
		this.routeParams = this.$route.query
	},

	beforeDestroy() {
		EventBus.$off()
	},

	mounted() {
		if (AuthLogic.hasRole('admin')) {
			/*if (AuthLogic.user().real_id !== 1) {
				GlobalFiltersStore.startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
			}*/

			this.unitGroups = AuthLogic.user().unitGroups
			if (!GlobalFiltersStore.selectedUnits.length) {
				this.getAllUnitGroups(true)
			} else {
				this.getAllUnitGroups(false)
			}
		} else {
			this.unitGroups = AuthLogic.user().unitGroups
			//this.toggleUnitGroups()
			this.getInitialUnitGroupSelection();
		}

		this.applyFiltersFromURL()
	},

	components: {},

	computed: {
		/*Unit groups*/
		selectAllUnitGroups() {
			return GlobalFiltersStore.selectedUnitGroups.length === this.unitGroups.length
		},
		selectSomeUnitGroups() {
			return GlobalFiltersStore.selectedUnitGroups.length > 0 && !this.selectAllUnitGroups
		},
		selectAllUnitGroupsIcon() {
			if (this.selectAllUnitGroups) return 'mdi-close-box'
			if (this.selectSomeUnitGroups) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},

		/*Units*/
		selectAllUnits() {
			//need to filter headers and dividers
			return _.filter(this.units, function (o) {
				return o.id !== undefined;
			}).length === GlobalFiltersStore.selectedUnits.length && this.units.length
		},
		selectSomeUnits() {
			return GlobalFiltersStore.selectedUnits.length > 0 && !this.selectAllUnits
		},
		selectAllUnitsIcon() {
			if (this.selectAllUnits) return 'mdi-close-box'
			if (this.selectSomeUnits) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},

		/*Dates*/
		computedStartDateFormattedWithFullMonth() {
			return Helpers.parseDate(this.startDate, 'DD MMMM YYYY');
		},

		computedEndDateFormattedWithFullMonth() {
			return Helpers.parseDate(this.endDate, 'DD MMMM YYYY');
		},

		/*Channels*/
		selectAllChannels() {
			//need to filter headers and dividers
			return _.filter(this.channels, function (o) {
				return o.id !== undefined;
			}).length === GlobalFiltersStore.selectedChannels.length && this.channels.length
		},
		selectSomeChannels() {
			return GlobalFiltersStore.selectedChannels.length > 0 && !this.selectAllChannels
		},
		selectAllChannelsIcon() {
			if (this.selectAllChannels) return 'mdi-close-box'
			if (this.selectSomeChannels) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},

		startDate: {
			get() {
				return GlobalFiltersStore.startDate
			},
			set(newVal) {
				GlobalFiltersStore.startDate = newVal
			}
		},

		endDate: {
			get() {
				return GlobalFiltersStore.endDate
			},
			set(newVal) {
				GlobalFiltersStore.endDate = newVal
			}
		},

		hasFilters() {
			return !GlobalFiltersStore.selectedUnitGroups.length || !GlobalFiltersStore.selectedUnits.length || !this.startDate.length || !this.endDate.length || !GlobalFiltersStore.sources.length
		}
	},

	watch: {
		selectedUnitsText() {
			if (GlobalFiltersStore.selectedUnits.length) {
				this.getSourceFilters(this.withRouteParams);
				this.withRouteParams = true;
			}
		},

		$route(to, from) {
			// if (to.name !== from.name && to.name !== 'ratings') {
			this.setURLParamsFromGlobalFilters()
			// }
		},

		startDate() {
			if (moment(this.startDate).isAfter(this.endDate)) {
				this.endDate = this.startDate;
			}
		},

		endDate() {
			if (moment(this.endDate).isBefore(this.startDate)) {
				this.startDate = this.endDate;
			}
		}
	},

	methods: {
		getChannels(){
			GlobalFiltersStore.selectedChannels = []
			this.channels = _.uniqBy(_.flatMap(_.filter(GlobalFiltersStore.selectedUnits, function(ug) {
				if(ug.ratingChannels.length){
					return ug.ratingChannels
				}
			}), 'ratingChannels'), 'id');

			// GlobalFiltersStore.selectedChannels = this.channels
			this.channelsSelectDisabled = false
			this.channelsSelectLoading = false
		},
		// filterTopUnitGroups() {
		// 	let items = this.unitGroups.sort();
		// 	var selected = items.filter(s => this.GlobalFiltersStore.selectedUnitGroups.includes(s))
		// 	var notSelected = items.filter(s => !this.GlobalFiltersStore.selectedUnitGroups.includes(s))
		//
		// 	this.unitGroups = [...selected, ...notSelected];
		// },
		//
		// filterTopUnits() {
		// 	let items = this.units.sort();
		// 	var selected = items.filter(s => this.GlobalFiltersStore.selectedUnits.includes(s))
		// 	var notSelected = items.filter(s => !this.GlobalFiltersStore.selectedUnits.includes(s))
		//
		// 	this.units = [...selected, ...notSelected];
		// },

		/*Unit groups*/
		toggleUnitGroups() {
			this.$nextTick(() => {
				if (this.selectAllUnitGroups) {
					GlobalFiltersStore.selectedUnitGroups = [];
					this.units = [];
					GlobalFiltersStore.selectedUnits = [];
					this.unitsSelectDisabled = true;
					this.selectedUnitsText = '';
					this.selectedUnitsModalText = '';
					this.selectedUnitGroupsText = '';
					this.selectedUnitGroupsModalText = '';

					if (this.$refs.unitGroupAutoComplete) {
						this.$nextTick(() => {
							this.$refs.unitGroupAutoComplete.focus();
							this.$refs.unitGroupAutoComplete.activateMenu()
						});
					}

				} else {
					if (!_.isEmpty(this.unitGroupsSearchInput)) {
						//unionBy cause we add to the already selected ones
						GlobalFiltersStore.selectedUnitGroups
							= _.unionBy(
							_.filter(this.unitGroups, (o) => {
								return o.name.toUpperCase().indexOf(this.unitGroupsSearchInput.toUpperCase()) !== -1;
							}),
							GlobalFiltersStore.selectedUnitGroups, 'id');
					} else {
						//select all
						GlobalFiltersStore.selectedUnitGroups = this.unitGroups.slice()
					}
					this.getSelectedUnitGroupsWithUnits(false);
				}
			})
		},

		getInitialUnitGroupSelection() {
			if (AuthLogic.hasRole('admin') && !AuthLogic.user().preferredUnitGroup) {
				GlobalFiltersStore.selectedUnitGroups = [
					{
						id: "757ba0a2-497c-45fe-8439-06baf8be8c42",
						name: "Admin Liz",
						real_id: "242"
					}
				]
				this.getSelectedUnitGroupsWithUnits(false);

			} else if (AuthLogic.user().preferredUnitGroup) {
				GlobalFiltersStore.selectedUnitGroups.push(AuthLogic.user().preferredUnitGroup)
				this.getSelectedUnitGroupsWithUnits(false);
			} else {
				this.toggleUnitGroups()
			}
		},

		getAllUnitGroups(toogleUnits) {
			ModelLogic
				.getAll('units-groups', {
					sortBy: ['name'],
					sortDesc: [1]
				}, {})
				.then(({unitsGroups}) => {
					this.unitGroups = unitsGroups;
					if (toogleUnits) {
						this.getInitialUnitGroupSelection();
					} else {
						this.getSelectedUnitGroupsWithUnits(true)
					}
				})
				.catch((err) => {
					console.error(err)
				})
		},

		/*Units*/
		toggleUnits() {
			if (this.selectAllUnits) {
				GlobalFiltersStore.selectedUnits = [];

				if (this.$refs.unitAutoComplete) {
					this.$nextTick(() => {
						this.$refs.unitAutoComplete.focus();
						this.$refs.unitAutoComplete.activateMenu()
					});
				}
			} else {
				if (!_.isEmpty(this.unitSearchInput)) {
					//unionBy cause we add to the already selected ones
					GlobalFiltersStore.selectedUnits
						= _.unionBy(
						_.filter(this.units, (o) => {
							return o.name.toUpperCase().indexOf(this.unitSearchInput.toUpperCase()) !== -1;
						}),
						GlobalFiltersStore.selectedUnits, 'id');
				} else {
					//select all
					//we need to remove header and dividers from this.units
					GlobalFiltersStore.selectedUnits = _.filter(this.units, function (o) {
						return o.id !== undefined;
					}).slice();
				}
			}
			this.getSelectedUnitsText()
		},

		/*Units*/
		toggleChannels() {
			if (this.selectAllChannels) {
				GlobalFiltersStore.selectedChannels = [];

				if (this.$refs.channelsAutoComplete) {
					this.$nextTick(() => {
						this.$refs.channelsAutoComplete.focus();
						this.$refs.channelsAutoComplete.activateMenu()
					});
				}
			} else {
				GlobalFiltersStore.selectedChannels = _.map(this.channels, 'uuid');
			}
		},

		getSelectedUnitGroupsWithUnits(selectAllUnits) {
			let ug = this.unitGroups.sort();
			const selected = ug.filter(s => GlobalFiltersStore.selectedUnitGroups.includes(s))
			const notSelected = ug.filter(s => !GlobalFiltersStore.selectedUnitGroups.includes(s))
			this.unitGroups = [...selected, ...notSelected];

			this.unitGroupsSearchInput = '';
			if (GlobalFiltersStore.selectedUnitGroups.length) {
				this.unitsSelectLoading = true;
				this.unitsSelectDisabled = true;
				this.channelsSelectLoading = true;
				this.channelsSelectDisabled = true;
				ModelLogic
					.getAll('units-groups/index', {
							sortBy: ['name'],
							sortDesc: [1]
						},
						{
							with: ['units'],
							with_fractal : ['units', 'units.ratingChannels'],
							in: GlobalFiltersStore.selectedUnitGroups.map(a => a.id)
						}, 'POST')
					.then(({unitsGroupsIndex, totalItems}) => {
						let final_data = [];

						//foreach unit group receive, store units
						_.forEach(unitsGroupsIndex, function (unitGroup, index) {
							//push divider and group names
							/*if (index > 0) {
							  final_data.push({divider: true})
							}
							final_data.push({header: unitGroup.name + ' (' + unitGroup.units.length + ')'})*/
							final_data = final_data.concat(unitGroup.units)
						});

						//remove duplicates as one unit can be in many unit group
						final_data = _.uniqBy(final_data, 'id');

						//check again if selectUnitGroups is not empty. As we dont disable unitGroup input, user can hard click
						//so uncheck all before this request is finished, and so will display a unit list
						if (GlobalFiltersStore.selectedUnitGroups.length) {
							this.units = final_data;
						} else {
							this.units = [];
							GlobalFiltersStore.selectedUnits = [];
						}

						if (!selectAllUnits) {
							//Select all units of unit-group
							GlobalFiltersStore.selectedUnits = _.filter(this.units, function (o) {
								return o.id !== undefined;
							}).slice();
							// } else {
							// 	if (!GlobalFiltersStore.selectedUnits.length) {
							// 		this.openDialog()
							// 	}
						}
						// GlobalFiltersStore.selectedUnits = final_data;
						// GlobalFiltersStore.selectedUnits = final_data;
						//remove selected units that are not available anymore
						//comment this if you want unit checkbox to be rechecked if user unselect and select again unit group of those units
						GlobalFiltersStore.selectedUnits = _.remove(GlobalFiltersStore.selectedUnits, (n) => {
							return this.units.some(e => e.id === n.id); //todo is there a better way to do this?
						});


						if (this.firstLaunch) {
							this.firstLaunch = !this.firstLaunch
							this.emitUpdateGlobalFiltersEvent()
						}

						this.unitsSelectLoading = false;
						this.unitsSelectDisabled = false;
					})
					.catch((err) => {
						this.unitsSelectLoading = false;
						this.unitsSelectDisabled = false;
						console.error(err)
					})
					.finally((err) => {
						this.getSelectedUnitGroupsText()
						this.getSelectedUnitsText()
						this.getChannels()
					})

			} else {
				this.units = [];
				GlobalFiltersStore.selectedUnits = [];
				this.unitsSelectDisabled = true;
			}
		},

		emitUpdateGlobalFiltersEvent: _.debounce(function () {
			if (GlobalFiltersStore.selectedUnitGroups.length && GlobalFiltersStore.selectedUnits.length && GlobalFiltersStore.startDate.length && GlobalFiltersStore.endDate.length && GlobalFiltersStore.sources.length) {
				EventBus.$emit('update-global-filters', {
					units: GlobalFiltersStore.selectedUnits,
					unit_groups: GlobalFiltersStore.selectedUnitGroups,
					sources: GlobalFiltersStore.sources,
					channels: GlobalFiltersStore.selectedChannels,
					dates: [GlobalFiltersStore.startDate, GlobalFiltersStore.endDate],
				});

				this.dialog = false;

				// if (this.$route.name !== 'ratings') {
					this.setURLParamsFromGlobalFilters()
				// }
			}
		}, 200),

		/*Dialog*/
		openDialog() {
			return new Promise(resolve => {
				this.dialog = true;
				resolve()
			})
		},

		closeDialog() {
			return new Promise(resolve => {
				this.dialog = false;
				resolve()
			})
		},

		getSelectedUnitGroupsText() {
			this.selectedUnitGroupsText = '';
			this.selectedUnitGroupsModalText = '';

			if (GlobalFiltersStore.selectedUnitGroups.length === 0) {
				this.selectedUnitsText = '';
				this.selectedUnitsModalText = '';
			} else if (GlobalFiltersStore.selectedUnitGroups.length === 1) {
				this.selectedUnitGroupsText = this.selectedUnitGroupsModalText = GlobalFiltersStore.selectedUnitGroups[0].name
			} else if (GlobalFiltersStore.selectedUnitGroups.length > 1 && GlobalFiltersStore.selectedUnitGroups.length < this.unitGroups.length) {
				this.selectedUnitGroupsModalText = GlobalFiltersStore.selectedUnitGroups.length + ' ' + this.$t('views.unit_group.rating_page_filters.groups')
			} else if (GlobalFiltersStore.selectedUnitGroups.length === this.unitGroups.length) {
				this.selectedUnitGroupsModalText = GlobalFiltersStore.selectedUnitGroups.length + ' ' + this.$t('views.unit_group.rating_page_filters.groups') + ' (' + this.$t('views.unit_group.rating_page_filters.all') + ')'
			}
		},

		getSelectedUnitsText() {
			let u = this.units.sort();
			const selected = u.filter(s => GlobalFiltersStore.selectedUnits.includes(s))
			const notSelected = u.filter(s => !GlobalFiltersStore.selectedUnits.includes(s))
			this.units = [...selected, ...notSelected];


			let txt = "";
			let modalTxt = "";
			if (GlobalFiltersStore.selectedUnits.length === 0) {
				txt = modalTxt = ''
			} else if (GlobalFiltersStore.selectedUnits.length === 1) {
				txt = modalTxt = GlobalFiltersStore.selectedUnits[0].name
			} else if (GlobalFiltersStore.selectedUnits.length > 1 && GlobalFiltersStore.selectedUnits.length < this.units.length) {
				const type_plural = _(Object.assign({}, GlobalFiltersStore.selectedUnits))
					.groupBy('type_plural')
					.map((items, name) => ({name, count: items.length}))
					.value();

				if (type_plural.length === 1 && type_plural[0].name !== '') {
					txt = GlobalFiltersStore.selectedUnits.length + ' ' + type_plural[0].name
					modalTxt = GlobalFiltersStore.selectedUnits.length + ' ' + type_plural[0].name
				} else {
					txt = modalTxt = GlobalFiltersStore.selectedUnits.length + ' ' + this.$t('views.unit.rating_page_filters.units')
				}
			} else if (GlobalFiltersStore.selectedUnits.length === this.units.length) {
				const type_plural = _(Object.assign({}, GlobalFiltersStore.selectedUnits))
					.groupBy('type_plural')
					.map((items, name) => ({name, count: items.length}))
					.value();

				if (type_plural.length === 1 && type_plural[0].name !== '') {
					txt = GlobalFiltersStore.selectedUnits.length + ' ' + type_plural[0].name
					modalTxt = GlobalFiltersStore.selectedUnits.length + ' ' + type_plural[0].name + ' (' + this.$t('views.unit.rating_page_filters.all') + ')'
				} else {
					txt = GlobalFiltersStore.selectedUnits.length + ' ' + this.$t('views.unit.rating_page_filters.units')
					modalTxt = GlobalFiltersStore.selectedUnits.length + ' ' + this.$t('views.unit.rating_page_filters.units') + ' (' + this.$t('views.unit.rating_page_filters.all') + ')'
				}
			}

			this.selectedUnitsText = txt;
			this.selectedUnitsModalText = modalTxt;
		},

		searchFilters(item, queryText, itemText) {
			return item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},

		/*Sources*/
		async getSourceFilters(withRouteParams = true) {
			this.loadingSourcesFilter = true
			this.sourceFilters = [];

			let has_civiliz_source = false;
			let has_liz_source = false;
			let has_google_source = false;
			let has_google_places_source = false;
			let has_import_source = false;

			await _.forEach(GlobalFiltersStore.selectedUnits, (unit) => {
				if (unit.rating_total_count > 0) {
					if (unit.rating_google_place_count > 0 && !has_google_places_source) {
						has_google_places_source = true;
						this.sourceFilters.push({
							value: 'google_places',
							name: this.$t('views.rating.filters.source.google_places')
						})
					}
					if (unit.rating_gmb_count > 0 && !has_google_source) {
						has_google_source = true;
						this.sourceFilters.push({
							value: 'google',
							name: this.$t('views.rating.filters.source.google')
						})

					}
					if (unit.rating_civiliz_count > 0 && !has_civiliz_source) {
						has_civiliz_source = true;
						this.sourceFilters.push({
							value: 'civiliz',
							name: this.$t('views.rating.filters.source.civiliz')
						})
					}
					if (unit.rating_import_count > 0 && !has_import_source) {
						has_import_source = true;
						this.sourceFilters.push({
							value: 'import',
							name: this.$t('views.rating.filters.source.import')
						})
					}
					if (unit.rating_liz_count > 0 && !has_liz_source) {
						has_liz_source = true;
						this.sourceFilters.push({
							value: 'liz',
							name: this.$t('views.rating.filters.source.liz')
						})
					}
				}
			});
			await this.getSourceUserFilters();

			if (!_.isEmpty(this.routeParams) && _.has(this.routeParams, 'sources') && withRouteParams) {
				GlobalFiltersStore.sources = []
				await _.forEach(this.routeParams['sources'].split(','), (source) => {
					if (_.includes(_.map(this.sourceFilters, 'value'), source)) {
						GlobalFiltersStore.sources.push(source)
					}
				})
			} else {
				if (!GlobalFiltersStore.sources.length || !withRouteParams) {
					GlobalFiltersStore.sources = _.map(this.sourceFilters, 'value');
				}
			}

			this.loadingSourcesFilter = false
		},

		async getSourceUserFilters() {
			let userSources = _.map(AuthLogic.user().ratingSources, 'slug');
			userSources = userSources.length ? userSources : ['google', 'civiliz', 'import', 'liz']; //default when user has no sources

			if (userSources.length) {
				let existingSourceFilters = _.map(this.sourceFilters, 'value');
				let sourcesToRemove = _.difference(existingSourceFilters, userSources)
				let sourceFilters = []

				await _.forEach(this.sourceFilters, (source, index) => {
					if (!_.includes(sourcesToRemove, source.value)) {
						sourceFilters.push(source);
					}
				});
				this.sourceFilters = sourceFilters
			}
		},

		applyFiltersFromURL() {
			if (!_.isEmpty(this.routeParams)) {
				//Dates
				if (_.has(this.routeParams, 'sd')) {
					GlobalFiltersStore.startDate = this.routeParams['sd']
				}

				if (_.has(this.routeParams, 'ed')) {
					GlobalFiltersStore.endDate = this.routeParams['ed']
				}

				if (_.has(this.routeParams, 'uids')) {
					GlobalFiltersStore.uids = this.routeParams['uids']
				}

				if (_.has(this.routeParams, 'cuids')) {
					GlobalFiltersStore.selectedChannels =GlobalFiltersStore.cuids = this.routeParams['cuids'].split(',')
		        }
			}
		},

		async setURLParamsFromGlobalFilters() {
			const urlParams = {}

			if (GlobalFiltersStore.startDate) {
				urlParams['sd'] = GlobalFiltersStore.startDate;
			}

			if (GlobalFiltersStore.endDate) {
				urlParams['ed'] = GlobalFiltersStore.endDate;
			}

			if (GlobalFiltersStore.sources.length) {
				urlParams['sources'] = GlobalFiltersStore.sources.join();
			}

			if (GlobalFiltersStore.selectedChannels.length) {
				urlParams['cuids'] = GlobalFiltersStore.selectedChannels.join();
			}

			if (!_.isEqual(JSON.parse(JSON.stringify(this.$route.query)), urlParams)) {
				await this.$router.push({query: Object.assign({}, urlParams)});
				this.routeParams = this.$route.query
			}
		},
	},
}
</script>
<style>
>>> .top-dialog {
	align-self: flex-start;
}

#GF .v-text-field__prefix {
	white-space: nowrap !important;
	overflow: hidden !important;
	-o-text-overflow: ellipsis !important;
	text-overflow: ellipsis !important;
}
</style>