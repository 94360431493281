import moment from "moment";

class GlobalFiltersStore {
    constructor() {
        //WARNING THINK TO UPDATE LOGOUT RESET STORE
        this.selectedUnitGroups = [];
        this.selectedUnits = [];
        this.selectedChannels = [];
        this.startDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
        this.sources = [];
        this.uids = [];
        this.cuids = [];

        this.comeFromPageWithoutGlobalFilters = false;
        this.comeFromRatingPage = false;
    }
}

export default new GlobalFiltersStore();
