import LazyLoad from '../services/LazyLoad'
import RouterLogic from "../logics/RouterLogic";
import GuardedLayout from "../views/Layout/GuardedLayout";

export default [
    {
        path: '/',
        component: GuardedLayout,
        beforeEnter: (to, from, next) => {
            RouterLogic.redirect(to, from, next)
        },
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: LazyLoad.localView('Dashboard')
            },
            {
                path: 'dashboards',
                name: 'dashboards',
                component: LazyLoad.localView('Dashboards/index')
            },
            {
                path: 'dashboards/:unitGroupUuid/:dashboardId',
                name: 'dashboard-unit-group',
                component: LazyLoad.localView('Dashboards/show')
            },
            {
                path: 'ratings',
                name: 'ratings',
                component: LazyLoad.localView('Rating/show'),
            },
        ],

    },
]
