import LazyLoad from '../services/LazyLoad'
import PublicLayout from "../views/Layout/PublicLayout";
import AuthLogic from "../logics/AuthLogic";

export default [
    {
        path: '/',
        component: PublicLayout,
        // beforeEnter: (to, from, next) => {
        //     RouterLogic.redirect(to, from, next)
        // },
        children: [
            {
                path: '',
                name: 'home',
                beforeEnter: (to, from, next) => {
                    if(AuthLogic.hasAccessToken()){
                        next({name: 'dashboard'})
                    } else {
                        next()
                    }
                },
                component: LazyLoad.localView('Home')
            },
            {
                path: 'password/reset',
                name: 'forgot-password',
                component: LazyLoad.localView('Auth/ResetPassword')
            },
            {
                path: 'password/update/:token',
                name: 'update-password',
                component: LazyLoad.localView('Auth/UpdatePassword')
            },
            {
                path: '/403',
                name: 'deny',
                component: LazyLoad.localView('40x/403'),
            },
            {
                path: '/404',
                name: 'not-found',
                component: LazyLoad.localView('40x/404'),
            },
            {
                path: '/prospect',
                name: 'prospect',
                component: LazyLoad.localView('Public/Prospect/GooglePlaceRegister'),
            },
            {
                path: '/prospect-dashboard',
                name: 'prospect-dashboard',
                component: LazyLoad.localView('Public/Prospect/GooglePlaceDashboard'),
            },
            {
                path: '/review/:slug',
                name: 'feedback',
                component: LazyLoad.localView('Public/Feedback/index'),
            },
            {
                path: '/display/:slug',
                name: 'display',
                component: LazyLoad.localView('Public/Display/index'),
            },
            {
                path: '/display-text/:slug',
                name: 'displayText',
                component: LazyLoad.localView('Public/Display/textIndex'),
            },
            {
                path: '/c/v', //short for sms
                name: 'consumerValidation',
                component: LazyLoad.localView('Public/Consumer/Validation'),
            },
            {
                path: '/auth/saml2',
                name: 'saml2Authentication',
                component: LazyLoad.localView('Public/Auth/Saml2Validation'),
            },
        ],
    },
    {
        path: '*',
        name: 'redirect',
        redirect: { name: 'dashboard' },
    }
]
