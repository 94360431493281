import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import i18n from "../config/i18n";
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
            minifyTheme,
        },
        themes: {
            light: {
                primary: '#489FBB',
                secondary: '#CDDC39',
                accent: '#489FBB',
                error: '#DD1236',
                warning: '#EF6C00',
                info: '#03a9f4',
                success: '#8bc34a',

                // Added variables
                background: '#f6f6f6',
                grey: {
                    base: '#CED3D4',
                    lighten1: '#F2F2F2',
                    lighten2: '#CFD0D0',
                },

                textGrey: '#828080',
                lightGrey: '#4a666f',
                darkGrey: '#3B4A4F',
                dark: '#2B2D2E',
                lime: '#CDDC39',
                blue: '#327186',
                white: '#FFFFFF',

                categorization0: '#489FBB',
                categorization1: '#37A94C',
                categorization2: '#8BC421',
                categorization3: '#CDDC39',
                categorization4: '#44C585',
                categorization5: '#279176',
                categorization6: '#489FBB',
                categorization7: '#37A94C',
                categorization8: '#8BC421',
                categorization9: '#CDDC39',
                categorization10: '#44C585',
                categorization11: '#279176',
                categorization12: '#489FBB',
                categorization13: '#37A94C',
                categorization14: '#8BC421',
                categorization15: '#CDDC39',

                categorization000: '#DD1236',
                categorization100: '#EF6C00',
                categorization200: '#FAA300',
                categorization300: '#FACA1B',
                categorization400: '#FF686D',
                categorization500: '#F54E00',
                categorization600: '#DD1236',
                categorization700: '#EF6C00',
                categorization800: '#FAA300',
                categorization900: '#FACA1B',
                categorization1000: '#FF686D',
                categorization1100: '#EF6C00',
                categorization1200: '#F54E00',
                categorization1300: '#DD1236',
                categorization1400: '#EF6C00',
                categorization1500: '#FAA300',
            },
            dark: {
                primary: '#489FBB',
                secondary: '#CDDC39',
                accent: '#489FBB',
                error: '#DD1236',
                warning: '#EF6C00',
                info: '#03a9f4',
                success: '#8bc34a',

                // Added variables
                background: colors.grey.darken3,
                white: colors.grey.darken4,

                grey: {
                    base: '#CED3D4',
                    lighten1: '#F2F2F2',
                },

                textGrey: '#555f62',
                lightGrey: '#8d8d8d',
                darkGrey: '#3b4a4f',
                dark: '#2B2D2E',
                lime: '#CDDC39',
                blue: '#327186',

                categorization0: '#489FBB',
                categorization1: '#37A94C',
                categorization2: '#8BC421',
                categorization3: '#CDDC39',
                categorization4: '#44C585',
                categorization5: '#279176',
                categorization6: '#489FBB',
                categorization7: '#37A94C',
                categorization8: '#8BC421',
                categorization9: '#CDDC39',
                categorization10: '#44C585',
                categorization11: '#279176',
                categorization12: '#489FBB',
                categorization13: '#37A94C',
                categorization14: '#8BC421',
                categorization15: '#CDDC39',

                categorization000: '#DD1236',
                categorization100: '#EF6C00',
                categorization200: '#FAA300',
                categorization300: '#FACA1B',
                categorization400: '#FF686D',
                categorization500: '#F54E00',
                categorization600: '#DD1236',
                categorization700: '#EF6C00',
                categorization800: '#FAA300',
                categorization900: '#FACA1B',
                categorization1000: '#FF686D',
                categorization1100: '#EF6C00',
                categorization1200: '#F54E00',
                categorization1300: '#DD1236',
                categorization1400: '#EF6C00',
                categorization1500: '#FAA300',
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    icons: {
        iconfont: 'mdi',
    },
});

// const themeCache = new LRU({
//   max: 10,
//   maxAge: 1000 * 60 * 60, // 1 hour
// })