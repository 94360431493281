const storageKey = process.env.VUE_APP_LOCAL_STORAGE_KEY||'liz';

export default class Storage {
    /**
     * return the custom key
     *
     * @param key
     * @returns {string}
     */
    static getKey(key) {
        return `${storageKey}-${key}`;
    }

    /**
     * Store a value.
     *
     * @param {string} key
     * @param {*} value
     *
     * @returns {Storage}
     */
    static set(key, value) {
        localStorage.setItem(this.getKey(key), JSON.stringify(value))

        return this;
    }

    /**
     * Get value from selected item.
     *
     * @param {string} key
     */
    static get(key) {
        return JSON.parse(localStorage.getItem(this.getKey(key)));
    }

    /**
     * Remove selected item.
     *
     * @param {string} key
     * @returns {Storage}
     */
    static remove(key) {
        if (localStorage.getItem(this.getKey(key)) !== null) {
            localStorage.removeItem(this.getKey(key));
        }

        return this;
    }
}
